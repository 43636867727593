import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api, { getPdfQr } from '../Base/Config';
import Message from '../Components/Message/Message';
import styles from './qr-details.module.css';

export default function PDFDetails(){
    const string = useParams().string;
    const [loader, setLoader] = useState(true);
    const [details, setDetails] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    useEffect(() => {
        fetchFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchFunction = () => {
        api.get(getPdfQr + string)
        .then(res => {
            setLoader(false);
            if(res.data.code === 200) {
                setDetails(res.data.result);
            } else {
                console.log(res);
                setMessageType('error');
                setMessage(res.data.message);
            }
        })
        .catch(err => {
            setLoader(false);
            console.error(err);
            setMessageType('error');
            setMessage('Please try again, Sorry for the trouble.');
        })
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        <div className={styles['loader']}>
            {
                loader
                ?<img src="../images/loader.gif" alt="loader" style={{width:'100px'}} />
                :<iframe src={`${details}#toolbar=0`} style={{width:'90vw',height:'90vh'}} title="pdf"></iframe>
            }
        </div>
    </>)
}
