import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api, { getQrMedia } from '../Base/Config';
import Message from '../Components/Message/Message';
import styles from './qr-details.module.css';

export default function MediaDetails(){
    const string = useParams().string;
    const [loader, setLoader] = useState(true);
    const [fileType, setFileType] = useState('');
    const [details, setDetails] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    useEffect(() => {
        fetchFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchFunction = () => {
        api.get(getQrMedia + string)
        .then(res => {
            setLoader(false);
            if(res.data.code === 200) {
                const fileType = res.data.result.split('.').reverse()[0];
                if(fileType === 'mp3' || fileType === 'aac' || fileType === 'wav') {
                    setFileType('Audio');
                } else if(fileType === 'mp4' || fileType === 'mov' || fileType === 'wmv' || fileType === 'avi' || fileType === 'mkv' || fileType === 'webm') {
                    setFileType('Video');
                } else if(fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') {
                    setFileType('Image');
                }
                setDetails(res.data.result);
            } else {
                console.log(res);
                setMessageType('error');
                setMessage(res.data.message);
            }
        })
        .catch(err => {
            setLoader(false);
            console.error(err);
            setMessageType('error');
            setMessage('Please try again, Sorry for the trouble.');
        })
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        <div className={styles['loader']}>
            {
                loader
                ?<img src="../images/loader.gif" alt="loader" style={{width:'100px'}} />
                :<div style={{padding: '20px'}}>
                    {fileType === 'Audio' && <>
                        <audio controls>
                            <source src={details} type="audio/mpeg" />
                        </audio>
                    </>}
                    {fileType === 'Video' && <>
                        <video width="320" height="240" controls autoplay>
                            <source src={details} type="video/mp4" />
                        </video>
                    </>}
                    {fileType === 'Image' && <>
                        <img src={details} alt="media" />
                    </>}
                </div>
            }
        </div>
    </>)
}
