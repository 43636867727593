import React from "react";
import Navbar from "./Components/Navbar/Navbar";
import about from "./assets/images/aboutus.svg";
import aboutdeco from "./assets/images/about_text-deco.svg";
import ourmission from "./assets/images/our mission.svg";
import ourmissiondeco from "./assets/images/ourmission-deco.svg";
import ourmissiondeco1 from "./assets/images/ourmissiondeco-1.svg";
import ourmissiondeco2 from "./assets/images/ourmissiondeco-2.svg";
import ourmissiondeco3 from "./assets/images/ourmissiondeco-3.svg";
import ourcustomers from "./assets/images/our customers.svg"
import Footer from "./Components/Footer/Footer";

export default function About() {
  return (
    <>
      <Navbar />
      <main>
        <div className="md:flex md:justify-between">
          <div className="w-full flex flex-col items-center">
            <div className="pt-[50px] m:pt-[50px] sm:pt-[50px] flex items-center md:pt-[30px] lg:pt-[50px]">
              <img
                className="-mt-2 sm:w-[86px] md:w-[45px] lg:w-[56px] xl:w-[76px] 1xl:w-[86px] 1xl:-mt-2"
                src={aboutdeco}
                alt=""
              />
              <h1 className="text-Primary font-bold text-4xl -ml-[55px] m:text-4xl m:-ml-[55px] sm:-ml-[55px] sm:text-4xl md:-ml-[35px] md:text-2xl lg:-ml-[30px] lg:text-xl xl:-ml-[48px] xl:text-3xl 1xl:text-[50px] 1xl:-ml-[75px]">
                About Us
              </h1>
            </div>
            <div className="flex justify-center pt-[20px] pl-[10px] pr-[10px] m:pl-[15px] m:pr-[15px] sm:pl-[15px] sm:pr-[15px] md:pt-[4px] md:pl-[8px] md:pr-0 lg:pl-0 lg:pt-[20px] lg:w-[450px] xl:w-[600px]">
              <p className=" m-0 text-center text-Primary text-[20px] leading-8 font-medium m:text-[20px] sm:text-[20px] md:text-[15px] md:leading-[22px] lg:text-[17px] lg:leading-7 xl:text-[23px] xl:leading-8 1xl:text-[25px] 1xl:leading-9">
                ScanOnn is dedicated to empowering businesses with smart,
                technology-driven solutions that redefine the way they interact
                with customers. Specializing in QR code and NFC innovations, we
                provide tools that enable seamless data exchange, enhance brand
                engagement, and streamline operations. Our solutions are built
                to integrate smoothly with modern business needs, offering
                reliability, security, and ease of use.
              </p>
            </div>
          </div>

          <div className="w-full">
            <div>
              <img className="w-full" src={about} alt="" />
            </div>
          </div>
        </div>

        <div className="bg-customdiv w-full relative s:px-3 s:py-3 m:px-[49px] m:py-[30px] 1xl:px-[49px] 1xl:py-[30px]">
          <div className="bg-white w-full flex flex-col items-center rounded-[18px]">
            <div className="border-x-2 border-b-2 rounded-b-[10px] px-[50px] py-[30px] bg-customdivhead s:px-[40px] s:py-[20px] m:px-[50px] m:py-[15px] sm:px-[100px] sm:py-[20px] md:px-[150px] md:py-[20px] lg:px-[216px] lg:py-[30px] 1xl:px-[216px] 1xl:py-[30px]">
              <h1 className="text-[20px] font-bold text-Primary m:text-[25px] sm:text-[30px] md:text-[30px] lg:text-[43px] 1xl:text-[43px]">
                Our Mission
              </h1>
            </div>
            <div className="w-full flex items-center justify-center">
              <img src={ourmission} alt="" />
            </div>

            <div className="w-full flex flex-col justify-center gap-[40px] pt-[70px] pb-[70px] xl:flex-row xl:gap-[50px] xl:px-20 1xl:gap-[95px] 1xl:pb-[150px]">
              <div className=" w-full flex flex-col items-center xl:items-start 1xl:w-[500px]">
                <div className="flex items-center justify-center w-full">
                  <h1 className="text-[25px] font-bold text-Primary z-10 m:text-[20px] sm:text-[25px] md:text-[30px] lg:text-[35px]">
                    Our Mission
                  </h1>
                  <img
                    className="-mt-3 -ml-12 m:w-[40px] m:-ml-[28px] sm:-ml-9 sm:w-[50px] md:-ml-12 md:w-[60px] lg:w-[71px]"
                    src={ourmissiondeco}
                    alt=""
                  />
                </div>
                <div>
                  <p className="text-[15px] font-medium text-Primary leading-8 text-center px-3 m:leading-7 m:text-[14px] sm:leading-8 sm:px-5 sm:text-[15px] md:text-[18px] lg:text-[20px] xl:text-start xl:px-0">
                    To empower businesses of all sizes by providing secure,
                    efficient, and scalable solutions in QR code and NFC
                    technology, enhancing their ability to reach and engage
                    customers in the digital age.
                  </p>
                </div>
              </div>

              <div className="w-full flex flex-col items-center xl:items-start 1xl:w-[500px]">
                <div className="flex items-center justify-center w-full">
                  <h1 className="text-[20px] font-bold text-Primary z-10 m:text-[20px] sm:text-[25px] md:text-[30px] lg:text-[35px]">
                    Our Vision
                  </h1>
                  <img
                    className="-mt-3 -ml-11 m:w-[40px] m:-ml-6 sm:-ml-8 sm:w-[50px] md:w-[60px] md:-ml-11 lg:w-[71px]"
                    src={ourmissiondeco}
                    alt=""
                  />
                </div>
                <div>
                  <p className="text-[15px] font-medium text-Primary leading-8 text-center px-3 m:leading-7 m:px-3 m:text-[14px] sm:leading-8 sm:px-5 sm:text-[15px] md:text-[18px] lg:text-[20px] xl:text-start xl:px-0">
                    To lead in innovative digital solutions that connect people
                    and technology effortlessly, fostering a world where
                    interaction and information flow seamlessly through
                    accessible, intuitive platforms.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="absolute bottom-0 left-0 w-[300px] s:w-[300px] m:w-[400px] lg:w-[550px] xl:w-[700px] 1xl:w-[787px]">
            <img src={ourmissiondeco1} alt="" />
          </div>
          <div className="absolute bottom-0 right-0 w-[90px] hidden m:block md:w-[110px] lg:w-[120px] xl:w-[130px] 1xl:w-[212px]">
            <img src={ourmissiondeco2} alt="" />
          </div>
          <div className="absolute top-0 left-0 w-[100px] m:w-[150px] sm:w-[200px] md:w-[250px] lg:w-[361px]">
            <img src={ourmissiondeco3} alt="" />
          </div>
        </div>

        <div className="flex flex-col items-center justify-center w-full">
          <div className="px-[63px] py-[26px] border bg-customdivhead w-[1004px]">
            <h4 className="font-bold text-[43px] text-Primary text-center leading-[60px]">
              "Empowering Business Connections through Technology"{" "}
            </h4>
          </div>
          <div className="px-[237px] pt-[30px]">
            <p className="text-center text-[30px] text-Primary font-medium">
              Our strategic focus is to innovate and continuously evolve our
              product suite, ensuring we meet the dynamic needs of businesses.
              Our roadmap includes expanding our white-label solutions,
              enhancing customization, and building a user-friendly ecosystem
              for QR and NFC applications tailored to industries such as retail,
              education, healthcare, and beyond.
            </p>
          </div>
          <div>
            <img src={ourcustomers} alt="" />
          </div>
        </div>
      </main>
      {/* <Footer /> */}
    </>
  );
}
